<template>
	<el-row :gutter="40" class="panel-group">
		<!-- <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
			<div class="card-panel" @click="handleSetLineChartData('newVisitis')">
				<div class="card-panel-icon-wrapper icon-new">
					<app-icon fill="#ff6723" class="card-panel-icon" name="sc-new" />
				</div>
				<div class="card-panel-description">
					<div class="card-panel-text">Comenzi noi</div>
					<count-to :start-val="0" :end-val="102400" :duration="2600" class="card-panel-num" />
				</div>
			</div>
		</el-col> -->
		<el-col :xs="24" :sm="24" :lg="24" class="card-panel-col" v-loading="isLoading">
			<div class="card-panel" @click="handleSetLineChartData('messages')">
				<div class="card-panel-icon-wrapper icon-complete">
					<svg-icon class="card-panel-icon" icon-class="sc-complete" />
				</div>
				<div class="card-panel-description">
					<div class="card-panel-text">{{$t('dashboard.left.stats-orders-finished')}}</div>
					<count-to :start-val="0" :end-val="finished" :duration="3000" class="card-panel-num" />
				</div>
				<div class="card-panel-footer">
					{{$t('dashboard.left.last-x-days')}}
				</div>
			</div>
		</el-col>
		<el-col :xs="24" :sm="24" :lg="24" class="card-panel-col" v-loading="isLoading">
			<div class="card-panel" @click="handleSetLineChartData('purchases')">
				<div class="card-panel-icon-wrapper icon-cancel-order">
					<svg-icon class="card-panel-icon" icon-class="sc-cancel" />
				</div>
				<div class="card-panel-description">
					<div class="card-panel-text">{{$t('dashboard.left.stats-orders-canceled')}}</div>
					<count-to :start-val="0" :end-val="canceled" :duration="3200" class="card-panel-num" />
				</div>
				<div class="card-panel-footer">
					{{$t('dashboard.left.last-x-days')}}
				</div>
			</div>
		</el-col>
		<el-col :xs="24" :sm="24" :lg="24" class="card-panel-col" v-loading="isLoading">
			<div class="card-panel" @click="handleSetLineChartData('shoppings')">
				<div class="card-panel-icon-wrapper icon-storno">
					<svg-icon class="card-panel-icon" icon-class="sc-storno" />
				</div>
				<div class="card-panel-description">
					<div class="card-panel-text">{{$t('dashboard.left.stats-orders-storno')}}</div>
					<count-to :start-val="0" :end-val="storno" :duration="3600" class="card-panel-num" />
				</div>
				<div class="card-panel-footer">
					{{$t('dashboard.left.last-x-days')}}
				</div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	import CountTo from 'vue-count-to';
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			CountTo,
		},
		methods: {
			handleSetLineChartData(type) {
				this.$emit('handleSetLineChartData', type);
			}
		},
		computed: {
			...mapState({
				isLoading: (state) => state.dashboard.compound.loading,
				loaded: (state) => state.dashboard.compound.loaded,
				finished: (state) => state.dashboard.compound.orders.stats.finished,
				canceled: (state) => state.dashboard.compound.orders.stats.canceled,
				storno: (state) => state.dashboard.compound.orders.stats.storno
			}),
		},
		mounted() {

		}
	};
</script>